import { Controller } from '@hotwired/stimulus'

import Formatter from '../../lib/formatter'
import Text from '../../lib/text'
import Validator from '../../lib/validator'

import loadImage from 'blueimp-load-image/js/load-image'

export default class extends Controller {
  // ============================================================================
  // ACTIONS
  // ============================================================================
  /*
   * While the user is typing, applies formating to the field, ensures that maxlength
   * is respected and toggles the "filled" class that controls the positioning of the
   * label.
   *
   * If the field has a counter associated with it, updates the counter.
   * If the field was marked with a validation error, removes it
   * If the form had alerts, remove them
   */

  submit (e) {
    e.currentTarget.closest('form').submit()
  }

  eventSubmit (e) {
    const event = new CustomEvent('submit', {
      bubbles: true,
      cancelable: true,
      detail: null
    })
    e.currentTarget.closest('form').dispatchEvent(event)
    return !event.defaultPrevented
  }

  input (e) {
    const input = e.currentTarget

    this.removeFieldError(input)
    this.removeFormAlerts(input)
    this.toggleFilled(input)
    this.applyFormat(input)
    this.enforceMaxLength(input)
    this.updateCounter(input)

    input.dataset.previousValue = input.value
  }

  /*
   * Does the same as 'input', but for the change event, which is used in selects
   */
  change (e) {
    const input = e.currentTarget

    this.removeFieldError(input)
    this.removeFormAlerts(input)
    this.toggleFilled(input)
    this.applyFormat(input)
    this.enforceMaxLength(input)

    input.dataset.previousValue = input.value
  }

  /*
   * When an element loses focus, apply any formatting that is delayed
   * to the blur (like cpf/cnpj mask) and validate the field
   */
  blur (e) {
    const input = e.currentTarget

    this.applyBlurFormat(input)
    this.validateField(input)
  }

  /*
   * Used by the ajax controller to request every field, in the form
   * it is about to submit, to validate itself. Called 'preflight'
   * because they are the final checks done before the payload is
   * sent.
   */
  preflight (e) {
    const target = e.currentTarget
    if ((target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') && target.type !== 'submit') {
      this.validateField(target)
    }
  }

  /*
   * Used on the submit buttons in forms, to validate all fields. If any
   * field fails validation, it cancels event propagation, preventing the
   * form from being submitted.
   */
  validate (e) {
    // Get form of clicked element
    const element = e.currentTarget
    let form

    if (element.tagName === 'FORM') {
      form = element
    } else if (typeof element.form !== "undefined") {
      form = element.form
    } else {
      form = element.closest('form')
    }

    const inputs = Array.from(form.querySelectorAll('input, textarea, select'))
    const valid = inputs.reduce((valid, input) => this.validateField(input) && valid, true)

    if (!valid) {
      this.focusFieldWithError(e)
      form.setAttribute('data-valid', false)

      e.stopImmediatePropagation()
      e.preventDefault()
    } else {
      form.removeAttribute('data-valid')
    }
  }

  /*
   * A special action used only by file inputs for a single file,
   * to allow them to display information for the user.
   *
   * For all types of file, it can display the filename.
   * For image files, it can use an img tag to display it.
   */
  file (e) {
    const input = e.currentTarget
    if (input.type !== 'file') return

    const { previewTarget, filenameTarget, contentTypeTarget } = input.dataset
    const file = e.target.files[0]

    this.validateFileSizeLimit(input, file)

    if (previewTarget) {
      if (!!file && (/image\/(jpg|jpeg|png|webp|gif|avif|heif|heic)/.test(file.type))) {
        loadImage(file, (img) => {
          document.querySelector(previewTarget).src = img.src
        }, { noRevoke: true })
      }
    }

    if (filenameTarget) {
      document.querySelector(filenameTarget).innerHTML = file.name
    }

    if (contentTypeTarget) {
      document.querySelector(contentTypeTarget).value = file.type
    }

    const label = input.closest('label')
    if (label.dataset.selectedText) {
      label.querySelector('span').textContent = label.dataset.selectedText
    }
  }

  /*
   * A special action used only by file inputs for multiple files
   * to allow then to display information for the user.
   *
   * For all types of file, it can display the filename.
   * For image files, it can use an img tag to display it.
   */
  files (e) {
    document.querySelectorAll('[data-direct-upload-entry]').forEach(element => element.remove())

    const filenameTarget = e.currentTarget.dataset.filenameTarget
    Array.from(e.target.files).forEach((file, index) => {
      const container = document.querySelector(filenameTarget)
      this.validateFileSizeLimit(e.currentTarget, file)

      const fileContainer = container.cloneNode(true)
      fileContainer.classList.remove('hidden')
      fileContainer.setAttribute('data-direct-upload-entry', true)
      fileContainer.querySelector('p').textContent = file.name

      container.insertAdjacentElement('afterEnd', fileContainer)
    })
  }

  /*
   * A special action used only by password inputs, to allow the user
   * to toggle between hidden password (******) and visible password (1234)
   */
  password (e) {
    const icon = e.currentTarget
    icon.children.forEach(child => {
      child.classList.toggle('hidden')
    })

    const input = icon.parentElement.querySelector('input')
    if (input.type === 'password') {
      input.type = 'text'
    } else {
      input.type = 'password'
    }
  }

  /*
   * A special action used only by the 'clicker' element of the fudgeball
   * design system. It increases or decreases the value of the associated input
   * by the amount set in the data-delta attribute, while respecting both
   * the data-maximum and data-minimum attributes.
   *
   * When either the maximum or the minimum are reached, it checks the
   * data-class of the respective buttons and toggles that class
   */
  clicker (e) {
    const button = e.currentTarget
    const parent = button.parentElement
    const input = parent.querySelector('input')
    const display = parent.querySelector('h6')

    const removable = parent.querySelector('[data-name="remove"]')
    const decrement = parent.querySelector('[data-name="decrement"]')
    const increment = parent.querySelector('[data-name="increment"]')

    let newValue = Number(input.value) + Number(button.dataset.delta)
    const minimum = Number(parent.dataset.minimum)
    const maximum = Number(parent.dataset.maximum)

    // Ensure newValue does not go beyong allowed bounds
    if (newValue <= minimum) {
      newValue = minimum
      decrement.classList.add('invisible')
      increment.classList.remove('invisible')
    } else if (newValue >= maximum) {
      newValue = maximum
      increment.classList.add('invisible')
      decrement.classList.remove('invisible')
    } else {
      decrement.classList.remove('invisible')
      increment.classList.remove('invisible')
    }

    input.value = newValue
    display.innerHTML = newValue

    // Behavior for removable switchers
    if (!removable) return

    const klass = removable.dataset.class
    const target = removable.dataset.target
    const toRemove = target ? document.querySelector(target) : parent

    if (newValue === 0) {
      toRemove.classList.add(klass)
    } else if (newValue === 1) {
      toRemove.classList.remove(klass)
      removable.classList.remove('hidden')
      decrement.classList.add('hidden')
    } else {
      toRemove.classList.remove(klass)
      removable.classList.add('hidden')
      decrement.classList.remove('hidden')
    }
  }

  // ============================================================================
  // ACTIONS
  // ============================================================================
  /*
   * Formats the current value of the input according to its data-formatter attribute
   */
  applyFormat (input) {
    const format = input.dataset.formatter
    if (!format || this.isUserDeleting(input)) return

    const result = Formatter.apply(format, input.value)
    const length = result.length

    input.value = result.substr(0, input.maxLength || length)

    if (input.type === 'text') {
      input.focus()
      input.setSelectionRange(length, length)
    }
  }

  /*
   * Formats the current value of the input according to its data-formatter attribute
   */
  applyBlurFormat (input) {
    const format = input.dataset.blurFormatter
    if (format) input.value = Formatter.apply(format, input.value)
  }

  /*
   * Enforces the maxlength on inputs and textareas. Both are needed because on android
   * chrome likes to ignore the maxlength of inputs.
   */
  enforceMaxLength (input) {
    const maxlength = input.dataset.maxlength
    const value = input.value

    if (!!maxlength && value.length >= maxlength) input.value = value.substr(0, maxlength)
  }

  /*
   * Helper used by the formatting function so it does not attempt to
   * apply formatting while the used is deleting characters from the input.
   */
  isUserDeleting (input) {
    const previous = input.dataset.previousValue || ''
    return previous.length > input.value.length
  }

  /*
   * Removes the errors classes of a field and its associated label, if any.
   */
  removeFieldError (input) {
    input.classList.remove('field_with_errors')
    input.removeAttribute('data-valid')

    const event = new Event('valid')
    input.dispatchEvent(event)

    if (input.nextElementSibling && input.nextElementSibling.tagName === 'LABEL') input.nextElementSibling.classList.remove('field_with_errors')
  }

  /*
   * Removes alert elements from the form, which are usually used to display specific
   * errors about fields.
   */
  removeFormAlerts (input) {
    if (input.form) {
      input.form.querySelectorAll('.alert:not(.alert-permanent)').forEach(alert => alert.remove())
      input.form.querySelectorAll('.alert-permanent').forEach(alert => alert.classList.add('hidden'))
    }
  }

  /*
   * Adds errors classes to a field and its associated label, if any.
   */
  showFieldErrors (input) {
    input.classList.add('field_with_errors')
    input.setAttribute('data-valid', false)

    const event = new Event('invalid')
    input.dispatchEvent(event)

    if (input.nextElementSibling && input.nextElementSibling.tagName === 'LABEL') input.nextElementSibling.classList.add('field_with_errors')
  }

  /*
   * If a field has a value selected, adds the form-field-filled class, which
   * makes the label move up. Otherwise removes it, which makes the label behave
   * as a sort of "placeholder"
   */
  toggleFilled (input) {
    input.classList.toggle('form-field-filled', !!input.value)
  }

  /*
   * Checks if an element has an associated counter, and updates it according
   * to the elements maxlength and current value length
   */
  updateCounter (input) {
    const counter = input.closest('.form-element').querySelector('.form-counter')
    const length = input.value.length
    const maxlength = input.maxLength

    if (counter) counter.textContent = `${length}/${maxlength}`
  }

  /*
   * Validates a field according to its data-validator or data-formatter attributes
   * Adds and removes error classes as needed
   */
  validateField (input) {
    if (input.disabled) return true

    const value = input.value
    const required = input.dataset.required
    const validator = input.dataset.validator || input.dataset.blurFormatter || input.dataset.formatter
    const minLength = input.minLength || 0
    let valid = true

    if (Text.isEmpty(value)) {
      valid = !required || required === 'false'
    } else if (minLength > value.length) {
      valid = false
    } else if (validator) {
      valid = Validator.assert(validator, value)
    }

    if (valid) {
      this.removeFieldError(input)
    } else {
      this.showFieldErrors(input)
    }

    return valid
  }

  /*
   * Enforces a limit of 200MB to all file uploads.
   */
  validateFileSizeLimit (input, file) {
    if (file.size > 209715200) {
      input.value = null

      const alert = document.querySelector('#file-alert')
      if (alert) {
        alert.querySelector('p').textContent = 'O arquivo tem que ter menos que 200MB'
        alert.classList.remove('hidden')
      }

      return false
    } else {
      const alert = document.querySelector('#file-alert')
      if (alert) alert.classList.add('hidden')

      return true
    }
  }

  /*
   * Checks if the form of an element is saving on input, if is not, focus on elements which has field_with_errors css class
   */
  focusFieldWithError (e) {
    const element = e.currentTarget
    let form

    if (element.tagName === 'FORM') {
      form = element
    } else if (typeof element.form !== "undefined") {
      form = element.form
    } else {
      form = element.closest('form')
    }
    const action = form.dataset.action
    const saveOnInput = (action.indexOf('input->ajax#') !== -1 && action.indexOf('paste->ajax#') !== -1)

    if (!saveOnInput) {
      document.querySelector('.field_with_errors').focus()
    }
  }

  /*
   * Returns true if the form of an element is set for saving on input, if is not, returns false
   */
  savingOnInput (e) {
    const element = e.currentTarget
    const form = element.tagName === 'FORM' ? element : element.form
    const action = form.dataset.action
    return (action.indexOf('input->ajax#') !== -1 && action.indexOf('paste->ajax#') !== -1)
  }

  addInput (e) {
    const div = document.createElement('div')
    div.className = 'removable mb-16'

    const formDiv = document.createElement('div')
    formDiv.className = 'form-element form-input form-input-text form-input-large'

    const input = document.createElement('input')
    input.type = 'text'
    input.name = e.target.dataset.inputName
    input.className = e.target.dataset.inputClass
    input.maxLength = 255
    input.size = 255
    input.setAttribute('data-target', 'form.input')
    input.setAttribute('data-action', 'input->form#input')
    input.setAttribute('placeholder', e.target.dataset.inputPlaceholder)

    const button = document.createElement('button')
    button.setAttribute('data-action', 'click->form#removeInput')

    formDiv.appendChild(input)

    div.appendChild(formDiv)
    div.appendChild(button)

    e.target.insertAdjacentElement('beforebegin', div)

    e.preventDefault()
  }

  removeInput (e) {
    e.target.parentNode.remove()
  }

  internationalPayment (e) {
    const input = e.currentTarget

    document.querySelectorAll('#payment_cpf_cnpj, #payment_phone').forEach(element => {
      if (input.checked) {
        this.removeFieldError(element)
        element.value = ''
        element.removeAttribute('data-required')
        element.setAttribute('disabled', true)
      } else {
        element.removeAttribute('disabled')
        element.setAttribute('data-required', true)
      }
    })
  }
}
