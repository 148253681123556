import Enums from './enums'
import Parser from './parser'
import Text from './text'

const Formatter = {
  /*
   * Applies the formatter for the specified string to the value
   * @params {string} format - The formatter to be applied
   * @params {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  apply (format, value) {
    return Formatter[format](value)
  },

  /*
   * Returns the age forcing a text part
   * @param {string} value - The age used to determine to plural form
   * @return {string} value - The text
   */
  age (val) {
    if (val.replace(/\d|e|\s/g, '').length === 0) {
      return val.trim() + ' ' + Formatter.short_age(val)
    } else {
      return val
    }
  },

  /*
   * Returns the diminutive age forcing a text part
   * @param {string} value - The age used to determine to plural form
   * @return {string} value - The text
   */
  age_diminutive (val) {
    if (val.replace(/\d|e|\s/g, '').length === 0) {
      return val.trim() + ' ' + Formatter.short_age_diminutive(val)
    } else {
      return val
    }
  },

  /*
   * Returns the numerical part of the age, adding "e" if there are multiple numbers
   * @param {string} value - The age used to determine the numbers
   * @return {string} value - The numbers
   */
  age_number (val) {
    const ages = val.match(/\d+/g)
    return ages ? ages.join(' e ') : ''
  },

  /*
   * Returns the textual part of the age, ensuring a value
   * @param {string} value - The age used to determine to plural form
   * @return {string} value - The text
   */
  age_text (val) {
    const tmp = val.replace(/ /g, '')
    const ages = val.match(/\d+/g)

    if (!ages) {
      return ''
    } else if (Formatter.numbers(val).length === tmp.length || (!!ages && ages.length > 1)) {
      return Formatter.short_age(val)
    } else {
      return Formatter.text(val)
    }
  },

  /*
   * Returns the age of the person with the prefix Festinha de. Eg.: Festinha de 10 anos
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  age_with_party_prefix (val) {
    val = Formatter.age(val)

    return val ? 'Festinha de ' + val : ''
  },

  /*
   * Returns the age of the person with the prefix Vem comemorar de. Eg.: Vem comemorar os 10 anos
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  age_with_invite_prefix (val) {
    const connective = Formatter.numbers(val) > 1 ? 'os' : 'o'
    val = Formatter.age(val)

    return val ? 'Vem comemorar ' + connective + ' ' + val : ''
  },

  /*
   * Removes from the value anything that is not a number or letter.
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  alphanumeric (val) {
    return val.replace(/[^a-z0-9]/ig, '')
  },

  /*
   * Removes from the value anything that is not a number or letter.
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  alphanumeric_and_spaces (val) {
    return val.replace(/[^a-z0-9 ]/ig, '')
  },

  /*
   * Returns the title according to the event classification
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  classification (val) {
    const hash = {
      birthday: 'Aniversário',
      birthday_adult: 'Aniversário',
      baptism: 'Batizado',
      baby_shower: 'Chá de bebê',
      diaper_shower: 'Chá de fraldas',
      first_communion: 'Primeira Comunhão',
      sweet_15: 'Festa',
      other: 'Festa'
    }

    return hash[val] || ''
  },

  /*
   * Returns the value formatted as a number, according to the user preferred language
   * @param {number} value - the value to be formatted
   */
  currency (val) {
    const locale = 'pt-BR' // Util.locale();
    const currency = 'BRL' // Util.currency();

    if (val) {
      return Number(val).toLocaleString(locale, { style: 'currency', currency: currency })
    } else {
      return Number(0).toLocaleString(locale, { style: 'currency', currency: currency })
    }
  },

  /*
   * Returns the formatted cpf cnpj
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  cpf_cnpj (val) {
    const tmp = Formatter.numbers(val)
    switch (tmp.length) {
      case 11:
        return Formatter.cpf(val)
      case 14:
        return Formatter.cnpj(val)
      default:
        return tmp
    }
  },

  /*
   * Returns the formatted cpf
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  cpf (val) {
    val = Formatter.numbers(val)

    if (val.length >= 9) val = val.substr(0, 9) + '-' + val.substr(9)

    if (val.length >= 6) val = val.substr(0, 6) + '.' + val.substr(6)

    if (val.length >= 3) val = val.substr(0, 3) + '.' + val.substr(3)

    return val
  },

  /*
   * Returns the formatted cnpj
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  cnpj (val) {
    val = Formatter.numbers(val)

    if (val.length >= 12) val = val.substr(0, 12) + '-' + val.substr(12)

    if (val.length >= 8) val = val.substr(0, 8) + '/' + val.substr(8)

    if (val.length >= 5) val = val.substr(0, 5) + '.' + val.substr(5)

    if (val.length >= 2) val = val.substr(0, 2) + '.' + val.substr(2)

    return val
  },

  /*
   * Returns the formatted credit_card
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  credit_card (val) {
    val = Formatter.numbers(val)

    if (val.length >= 12) val = val.substr(0, 12) + ' ' + val.substr(12)

    if (val.length >= 8) val = val.substr(0, 8) + ' ' + val.substr(8)

    if (val.length >= 4) val = val.substr(0, 4) + ' ' + val.substr(4)

    return val
  },

  /*
   * Returns a text field formatted as a credit card name
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  credit_card_name (val) {
    return Text.transliterate(val).toUpperCase().replace(/[^A-Z\s]/ig, '')
  },

  /*
   * Returns a text field formatted as a credit card expiration date. Eg.: 01/2018
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  credit_card_month_year (val) {
    val = val.replace(/\D/g, '')
    if (val.length >= 3) val = val.substr(0, 2) + '/' + val.substr(2, val.length)

    return val
  },

  /*
   * Returns a brazilian date. Eg.: 25/01/2018
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  date (val) {
    val = val.replace(/\D/g, '')

    if (val.length >= 4) val = val.substr(0, 4) + '/' + val.substr(4)

    if (val.length >= 2) val = val.substr(0, 2) + '/' + val.substr(2)

    return val
  },

  /*
   * Returns a brazilian date with a dot separator. Eg.: 25•01•2018
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  date_dot (val) {
    val = Formatter.date(val)

    val = val.replace(/\//g, '•')

    return val
  },

  /*
   * Returns a brazilian date with a dot separator. Eg.: 25.01.2018
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  date_period (val) {
    val = Formatter.date(val)

    val = val.replace(/\//g, '.')

    return val
  },

  /*
   * Returns the day in the specified date. Eg.: 24/01/2018 returns 24
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  day (val) {
    val = val.replace(/\D/g, '')

    if (val.length < 2) return ''

    return val.substr(0, 2)
  },

  /*
   * Returns the brazilian decimal form. Eg.: 1.000,00
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  decimal (val) {
    val = val.replace(/\D/g, '').replace(/^0+/, '')
    val = val.padStart(3, '0')

    if (val.length > 11) val = val.substr(0, val.length - 11) + '.' + val.substr(val.length - 11)

    if (val.length > 8) val = val.substr(0, val.length - 8) + '.' + val.substr(val.length - 8)

    if (val.length > 5) val = val.substr(0, val.length - 5) + '.' + val.substr(val.length - 5)

    if (val.length > 2) val = val.substr(0, val.length - 2) + ',' + val.substr(val.length - 2)

    return val
  },

  /*
   * Returns the formatted email phone
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  email_phone (val) {
    const hasOnlyNumbers = /^\d+$/.test(val)

    if (hasOnlyNumbers) {
      return Formatter.phone(val)
    }

    return val
  },

  /*
   * Returns the connective according to the child gender
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  gender_connective (val) {
    switch (val) {
      case 'female':
        return 'da'
      case 'male':
        return 'do'
      default:
        return 'do(a)'
    }
  },

  /* Removes all numbers */
  letters (val) {
    return val.replace(/[0-9]/g, '')
  },

  /*
   * Changes full hours into text. Eg.: 15:00 to 15 horas, 15:30 to 15:30
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  hour (val) {
    if (val.substr(3, 2) !== '00') return val

    return parseInt(val.substr(0, 2)) + ' horas'
  },

  /*
   * Changes full hours into hours with h separator. Eg.: 15:00 to 15h00
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  hour_h (val) {
    return val.replace(':', 'h')
  },

  /*
   * Changes full hours into hours with H separator. Eg.: 15:00 to 15H, 15:30 to 15H30
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  short_hour_h (val) {
    if (val.substr(2) === '00') {
      return val.substr(0, 2) + 'h'
    }
    return val.replace(':', 'h')
  },

  /*
   * Changes full hours into text and adds a preposition. Eg.: 15:00 to 'às 15 horas', 15:30 to 'às 15:30'
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
  */
  hour_connective (val) {
    return 'às ' + Formatter.hour(val)
  },

  /*
   * Returns full hours with a preposition. Eg.: às 15:00
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
  */
  simple_hour_connective (val) {
    return 'às ' + val
  },

  /*
   * Returns the string Niver
   * @param {string} value
   * @return {string} value - The formatted value
  */
  short_birthday_prefix (val) {
    return 'Niver'
  },

  /*
* Changes full hours adding prefixed text to it. Eg.: '15:00' to 'À partir das 15 horas', '01:00' to 'À partir da 01:00'
* @param {string} value - The value to be formatted
* @return {string} value - The formatted value
*/
  hour_with_prefix (val) {
    const prefix = val.substr(0, 2) === '01' ? 'A partir da' : 'A partir das'
    return prefix + ' ' + val
  },

  /*
   * Changes full hours into text and adds a prefix "Às" and suffix "Horas". Eg.: 15:00 to 'às Quinze horas', 15:30 to 'às Quinze e Trinta'
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  hour_text (val) {
    const [hour, minutes] = val.split(':')
    const hourText = parseInt(hour) === 1 ? 'à uma hora' : `às ${Enums.hours[hour]} horas`
    const minutesText = parseInt(minutes) === 30 ? 'e trinta minutos' : ''
    return [hourText, minutesText].join(' ')
  },

  /*
   * Returns the full month in the specified date. Eg.: 24/01/2018 returns Janeiro
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  month (val) {
    val = val.replace(/\D/g, '')

    if (val.length < 4) return ''

    return Enums.months[val.substr(2, 2)] || ''
  },

  /*
   * Returns only the numbers of month in the specified date. Eg.: 24/01/2018 returns 01
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  month_number (val) {
    val = val.replace(/\D/g, '')

    if (val.length < 4) return ''

    return val.substr(2, 2)
  },

  /*
   * Returns only the first letter of the person name inserted. Eg.: Lucas returns L
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  first_letter (val) {
    val = val.replace(/ /g, '')

    return val.substr(0, 1)
  },

  /*
   * Returns the name of the person with the prefix &. Eg.: & Lucas
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  name_with_prefix (val) {
    return val ? '& ' + val : ''
  },

  /*
 * Returns connector pluralized if val has /&/. Eg.: "Maria Fernanda & Isabella fazem 2 aninhos"
 * @param {string} value - The value to be formatted
 * @return {string} value - The formatted value
 */
  name_age_connector_pluralizable (val) {
    return val.match(/\s?[&\-,|]\s/)?.length > 0 ? val.replace(/\sfaz\s/, ' fazem ') : val
  },

  /*
   * Returns the title "Sugestão de presente: " with the gift suggestion message, if it is present
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  gift_suggestion_with_title (val) {
    return val ? 'Sugestão de presente: ' + val : ''
  },

  /*
   * Removes from the value anything that is not a number or a dash.
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  negative_number (val) {
    return val.replace(/[^-0-9]*/g, '')
  },

  /*
   * Removes from the value anything that is not a number.
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  number (val) {
    return val.replace(/\D/g, '')
  },

  /*
   * Alias for 'number'
   */
  numbers (val) {
    return Formatter.number(val)
  },

  /*
   * Removes from the value anything that is not a number and adds a upperscript o.
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  ordinal_number (val) {
    return Formatter.numbers(val) + '&#186;'
  },

  /*
   * Returns a brazillian phone number (new or old) with the area code. Eg.: (31) 1234-5678 or (31) 12345-6789
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  phone (val) {
    val = val.replace(/\D/g, '')

    if (val.length < 11) return legacyPhone(val)

    return newPhone(val)

    function legacyPhone (val) {
      if (val.length >= 6) val = val.substr(0, 6) + '-' + val.substr(6)

      if (val.length >= 2) val = val.substr(0, 2) + ') ' + val.substr(2)

      if (val.length > 0) val = '(' + val

      return val
    }

    function newPhone (val) {
      return '(' + val.substr(0, 2) + ') ' + val.substr(2, 5) + '-' + val.substr(7)
    }
  },

  international_phone (val) {
    return `+${val.replace(/\D/g, '')}`
  },

  modern_brazilian_format (val) {
    if (val.match(/^\(\d{2}\)\s[789]\d{3}\-\d{4}$/)) {
      val = val.substr(0, 5) + '9' + val.substr(5)
    }

    return val
  },

  auto_phone (val) {
    const isInternational = /^\s*(\+|55)/.test(val)
    const startsWithZero = val.startsWith('0')

    val = val.replace(/\D/g, '')

    if (isInternational) { val = val.substr(2) }

    if (startsWithZero) {
      val = parseInt(val).toString()
      if (val.length >= 13) { val = val.substr(-11) }
      if (val.length === 12) { val = val.substr(-10) }
    }

    if (val.length >= 12) { val = val.substr(0, 11) }

    if (val.length <= 11) {
      return Formatter.phone(val)
    } else {
      return Formatter.international_phone(val)
    }
  },

  modern_auto_phone (val) {
    val = Formatter.auto_phone(val)

    if (Text.isEmpty(val)) {
      return val
    } else if (val.startsWith('+') && val.length === 13 && ['7', '8', '9'].includes(val[5])) {
      return val.slice(0, 5) + '9' + val.slice(5)
    } else if (val.length === 14 && ['7', '8', '9'].includes(val[5])) {
      return val.slice(0, 5) + '9' + val.slice(5)
    } else {
      return val
    }
  },

  /*
   * Returns "anos" or "ano" depending on the child age.
   * @param {string} value - The age used to determine to plural form
   * @return {string} value - The text
   */
  short_age (val) {
    const age = Formatter.numbers(val)
    if (!age) return ''
    return Number(age) > 1 ? 'anos' : 'ano'
  },

  /*
   * Returns "aninhos" or "aninho" depending on the child age.
   * @param {string} value - The age used to determine to plural form
   * @return {string} value - The text
   */
  short_age_diminutive (val) {
    const age = Formatter.numbers(val)
    if (!age) return ''
    return Number(age) > 1 ? 'aninhos' : 'aninho'
  },

  /*
   * Returns the short month in the specified date. Eg.: 24/01/2018 returns Jan
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  short_month (val) {
    val = val.replace(/\D/g, '')

    if (val.length < 4) return ''

    return (Enums.months[val.substr(2, 2)] || '').substr(0, 3)
  },

  /*
   * Returns the short version of weekday in the specified date. Eg.: 24/01/2018 returns Quarta
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
  */
  short_weekday (val) {
    const date = Parser.date(val)
    if (!date) return ''

    return Enums.weekdays[date.getUTCDay()].replace('-feira', '')
  },

  /*
   * Returns the very short version of weekday in the specified date. Eg.: 24/01/2018 returns Qua
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
  */
  very_short_weekday (val) {
    const date = Parser.date(val)
    if (!date) return ''

    return Enums.weekdays[date.getUTCDay()].substr(0, 3)
  },

  /*
   * Transforms a text into a slug used in a url
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  slug (val) {
    return Text.parameterize(val)
  },

  /*
   * Removes all numbers from the value, keeping only the text, special characters and whitespace
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  text (val) {
    return val.replace(/\d/g, '').replace(/\s\s+/g, ' ').trim()
  },

  /*
   * Transforms a text into a valid url
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  url (val) {
    return Text.urlize(val)
  },

  /*
   * Returns the weekday in the specified date. Eg.: 24/01/2018 returns Quarta-Feira
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
  */
  weekday (val) {
    const date = Parser.date(val)
    if (!date) return ''

    return Enums.weekdays[date.getUTCDay()]
  },

  /*
   * Returns the year in the specified date. Eg.: 24/01/2018 returns 2018
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  year (val) {
    val = val.replace(/\D/g, '')

    if (val.length < 8) return ''

    return val.substr(4, 4)
  },

  /*
  * Only allo users to uppercase the first letter of each word
  * @param {string} value - The value to be formatted
  * @return {string} value - The formatted value
  */
  titlecase (val) {
    const result = []

    val.split(' ').forEach((word) => {
      result.push(word.charAt(0) + word.slice(1).toLowerCase())
    })

    return result.join(' ')
  },

  /*
   * Returns the last 2 digits from year in the specified date. Eg.: 24/01/2018 returns 18
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  short_year (val) {
    val = val.replace(/\D/g, '')

    if (val.length < 8) return ''

    return val.substr(6, 2)
  },

  /*
   * Returns the formatted sku
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  sku (val) {
    val = Formatter.alphanumeric(val).toUpperCase()

    if (val.length >= 17) val = val.substr(0, 17) + '-' + val.substr(17)

    if (val.length >= 8) val = val.substr(0, 8) + '-' + val.substr(8)

    if (val.length >= 3) val = val.substr(0, 3) + '-' + val.substr(3)

    return val
  },

  /*
   * Returns a brazilian zipcode. Eg.: 04521-004
   * @param {string} value - The value to be formatted
   * @return {string} value - The formatted value
   */
  zipcode (val) {
    val = val.replace(/\D/g, '')

    if (val.length >= 5) val = val.substr(0, 5) + '-' + val.substr(5)

    return val
  },

  /*
 * Returns the formatter rsvp date with prefix and bold. Eg.: <b>RSVP até 01/02:</b>
 * @param {string} value - The value to be formatted
 * @return {string} value - The formatted value
 */
  rsvp_with_prefix (val) {
    return '<b>RSVP até ' + val.substr(0, 5) + ':</b>'
  },

  /*
 * Add the ceremony prefix. Eg.: <b>Cerimônia:</b> Igreja</b>
 * @param {string} value - The value to be formatted
 * @return {string} value - The formatted value
 */
  ceremony_prefix (val) {
    return '<b>Cerimônia:</b> ' + val
  },

  /*
 * Add the ceremony prefix. Eg.: <b>Cerimônia:</b> Igreja</b>
 * @param {string} value - The value to be formatted
 * @return {string} value - The formatted value
 */
  reception_prefix (val) {
    return '<b>Recepção:</b> ' + val
  },

  /*
 * Add the celebrate prefix. Eg.: Comemora 10 anos
 * @param {string} value - The value to be formatted
 * @return {string} value - The formatted value
 */
  celebrate_prefix (val) {
    return 'Comemora ' + val
  },

  /*
  * Add the event welcoming prefix. Eg.: Te espero para o Chá de Bebê da Ingrid
  * @param {string} value - The value to be formatted
  * @return {string} value - The formatted value
  */
  event_title_welcoming (val) {
    return 'Te espero para o ' + val
  }
}

export default Formatter
