import { Controller } from '@hotwired/stimulus'

const directionMap = {
  left: 'scrollLeft',
  top: 'scrollTop'
}

export default class extends Controller {
  static targets = ['reference', 'scrollable', 'leftButton', 'rightButton']
  static values = { direction: String, autoScroll: Boolean }

  connect () {
    if (this.autoScrollValue) {
      this.scroll()
    } else if (this.hasLeftButtonTarget && this.hasRightButtonTarget) {
      this.updateButtonVisibility();
      this.scrollableTarget.addEventListener('scroll', this.updateButtonVisibility.bind(this));
    }
  }

  scroll () {
    const method = directionMap[this.directionValue]

    if (method) {
      this[method]()
    } else {
      console.error(`Invalid direction value: ${this.directionValue}`)
    }
  }

  scrollLeft (targetOffset = this.referenceTarget.offsetLeft) {
    this.scrollableTarget.scrollLeft = targetOffset;
  }

  scrollTop (targetOffset = this.referenceTarget.offsetTop) {
    if(this.hasScrollableTarget) {
      this.scrollableTarget.scrollTop = targetOffset;
    } else {
      window.scrollTo({top: targetOffset});
    }
  }

  scrollByParentWidth(event) {
    let scrollValue
    const isRight = event.currentTarget == this.rightButtonTarget

    if (isRight) {
      scrollValue = Math.min(this.scrollableTarget.scrollLeft + this.visibleWidth, this.maxScrollLeft)
    } else {
      scrollValue = Math.max(this.scrollableTarget.scrollLeft - this.visibleWidth, 0)
    }

    this.scrollLeft(scrollValue)
    this.updateButtonVisibility()
  }

  updateButtonVisibility() {
    if (this.scrollableTarget.scrollLeft <= 0) {
      this.leftButtonTarget.classList.add('hidden');
    } else {
      this.leftButtonTarget.classList.remove('hidden');
    }

    if (this.scrollableTarget.scrollLeft >= (this.maxScrollLeft - this.rightButtonTarget.clientWidth)) {
      this.rightButtonTarget.classList.add('hidden');
    } else {
      this.rightButtonTarget.classList.remove('hidden');
    }
  }

  get width () {
    return this.scrollableTarget.scrollWidth
  }

  get visibleWidth () {
    return this.scrollableTarget.clientWidth
  }

  get maxScrollLeft () {
    return this.width - this.visibleWidth
  }
}
